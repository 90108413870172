<template>
    <Teleport to="body">
        <!-- Google Tag Manager (noscript) -->
        <!-- eslint-disable vue/no-v-html -->
        <noscript v-html="noscriptCode" />
        <!-- eslint-enable vue/no-v-html -->
        <!-- End Google Tag Manager (noscript) -->
    </Teleport>
</template>

<script setup>
const { public: { GtmId } } = useRuntimeConfig();

const noscriptCode = ref(`<iframe src="https://www.googletagmanager.com/ns.html?id=${GtmId}" height="0" width="0" style="display: none; visibility: hidden" />`);

useHead({
    script: [
        {
            children: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${GtmId}');`
        }
    ]
});
</script>
